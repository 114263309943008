import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionCard from '../components/sectionCard'

import Branding_outline from '../svgs/Branding_outline.inline.svg'
import Advertising_outline from '../svgs/Advertising_outline.inline.svg'
import Maintenance_outline from '../svgs/Maintenance_outline.inline.svg'
import Strategy_outline from '../svgs/Strategy_outline.inline.svg'

import serviceData from '../data/services'
import anime from 'animejs/lib/anime.es.js';

import '../styles/leistungen.scss'

export const brandingComp = <Branding_outline className="svg h-32 lg:h-40"/>
export const advertisingComp = <Advertising_outline className="svg h-32 lg:h-40"/>
export const maintenanceComp = <Maintenance_outline className="svg h-32 lg:h-40"/>
export const strategyComp = <Strategy_outline className="svg h-32 lg:h-40"/>

const ServicesPage = () => {

  useEffect(() => {
    var card = document.getElementsByClassName('card-wrapper');
    var i = 0;

    for(i=0; i < card.length; i++) {
      card[i].children[0].querySelectorAll('.svg path').color = '#A8A8A8'

      card[i].addEventListener('mouseenter', function() {
        var svg = this.children[0].querySelectorAll('.svg path')
        var title = this.children[1].children[0]
        var text = this.children[1].children[1]
        var list = this.children[1].children[2]
        anime({
          targets: this,
          backgroundColor: ['#fff', '#000'],
          duration: 2000
        });
        anime({
          targets: title,
          color: ['#000', '#fff'],
          duration: 2000
        })
        anime({
          targets: text,
          color: ['#A8A8A8', '#fff'],
          duration: 2000
        })
        anime({
          targets: list,
          color: ['#A8A8A8', '#fff'],
          duration: 2000
        })
        anime({
          targets: svg,
          strokeDashoffset: [anime.setDashoffset, 0],
          easing: 'easeInOutSine',
          duration: 2000,
          delay: function(el, i) { return i * 250 },
          direction: 'alternate',
          loop: false
        });
        anime({
          targets: svg,
          stroke: ['#A8A8A8', '#fff'],
          duration: 1000
        })
      })
      card[i].addEventListener('mouseleave', function() {
        var svg = this.children[0].querySelectorAll('.svg path')
        var title = this.children[1].children[0]
        var text = this.children[1].children[1]
        var list = this.children[1].children[2]
        anime({
          targets: this,
          backgroundColor: ['#000', '#fff'],
          duration: 2000
        });
        anime({
          targets: title,
          color: ['#fff', '#000'],
          duration: 2000
        })
        anime({
          targets: text,
          color: ['#fff', '#A8A8A8'],
          duration: 2000
        })
        anime({
          targets: list,
          color: ['#fff', '#A8A8A8'],
          duration: 2000
        })
        anime({
          targets: svg,
          stroke: ['#fff', '#A8A8A8'],
          duration: 1000
        })
      })
    }
  })


  return (
  <Layout>
    <SEO title="Leistungen"
      description="Von der Strategie und Markenentwicklung über Werbung bis hin zur Maintenance bieten wir unseren Kunden maßgeschneiderte Lösungen, die sie wirklich brauchen. Schnell, einfach und mit dem gewissen Etwas."/>

    <div className="main-wrapper">
      <div id="title-section" className="flex flex-col justify-center my-20">
        <h1 className="text-3xl mb-10" data-aos="fade-in">Mit diesen Services können wir überzeugen</h1>
        <p className="leading-loose md:w-3/4 lg:w-1/2" data-aos="fade-in">
          {serviceData.serviceIntro}
        </p>
      </div>

      <div id="section-cards-section" className="flex flex-col mb-32 md:flex-row md:flex-wrap">
        <div id="cards-section-1" className="flex flex-col md:mr-2"> 
          <SectionCard number="01" bgTitle={brandingComp} list={serviceData.brandingList} title="Branding">
            {serviceData.brandingTextLong}
          </SectionCard>
          <SectionCard number="02" hiddenDesktop={true} bgTitle={strategyComp} list={serviceData.strategyList} title="Strategy">
            {serviceData.strategyTextLong}
          </SectionCard>
          <SectionCard hiddenMobile={true} hiddenDesktop={false} number="03" bgTitle={advertisingComp} list={serviceData.adList} title="Advertising">
            {serviceData.adTextLong}
          </SectionCard>
        </div>
        
        <div id="cards-section-2" className="flex flex-col md:mt-32 md:ml-2">
        <SectionCard hiddenDesktop={true} number="03" bgTitle={advertisingComp} list={serviceData.adList} title="Advertising">
            {serviceData.adTextLong}
          </SectionCard>
          <SectionCard number="02" hiddenMobile={true} hiddenDesktop={false} bgTitle={strategyComp} list={serviceData.strategyList} title="Strategy">
            {serviceData.strategyTextLong}
          </SectionCard>
          <SectionCard number="04" bgTitle={maintenanceComp} list={serviceData.maintainList} title="Maintenance">
            {serviceData.maintainTextLong}
          </SectionCard>
        </div>

      </div>
    </div>
    
  </Layout>
  )};

export default ServicesPage;