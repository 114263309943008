const serviceData = {
    brandingList: ['Corporate Design & Redesign', 'Design Systems', 'Positionierung', 'Markenstrategie',  'Web Design & Entwicklung'],
    adList: ['Kampagnen-Entwicklung', 'Digital Marketing', 'Grafische Umsetzung', 'Print Marketing', 'Content Marketing', 'Werbungs-Design'],
    strategyList: ['Marketing Strategie', 'Positionierung', 'Marken Strategie'],
    maintainList: ['Social Media Marketing', 'Website Betreuung', 'Content Creation', 'Motion Design', 'Grafik Design', 'Fotografie'],

    brandingText: 'Wir schaffen Marken und helfen ihnen zu wachsen. Von der Strategie über Storytelling und Identität bis hin zur Umsetzung. Ganz einfach.',
    adText: 'Zielgruppenorientierte Kommunikationskampagnen, die ins Schwarze treffen. Analog, Digital und mit dem gewissen Etwas.',
    strategyText: 'Der erste Schritt zu jeder Kampagne, Produkteinführung und allem, was einmal richtig erfolgreich werden soll.',
    maintainText: 'Weil wir unsere Klienten verstehen. Instandhaltung und Content Creation, die unseren Qualitätsstandards gerecht werden. Alles aus einer Hand.',

    brandingTextLong: 'Für unsere Brand Services verschmelzen wir die kundenrelevanten Komponenten aus den bereichen Corporate Design, Positioning und Brand Strategy, um einen ganzheitlichen Unternehmensauftritt zu gewährleisten und neue Stilelemente sanft einzugliedern.',
    adTextLong: 'Um unsere Kunden bestmöglich in Szene zu setzen, erschaffen wir einzigartige Kreationen mit visueller Anziehungskraft und hoher Qualität, welche sich in den Köpfen der Menschen festsetzen und einen positiven Einfluss auf Ihr Unternehmen ausüben.',
    strategyTextLong: 'Nachhaltiger Erfolg beginnt mit einer durchdachten Strategie. Um einen ganzheitlichen Unternehmensauftritt ohne Widersprüche zu gewährleisten, wägen wir alle unsere Design- und Kommunikationsentscheidungen anhand eines ausgeklügelten, strategischen Grundgerüstes ab, welches in enger Zusammenarbeit mit unseren Kunden entsteht.',
    maintainTextLong: 'Damit der anfängliche Glanz der von uns erstellten Lösungen noch lange erhalten bleibt, bieten wir Kunden die Möglichkeit zur Nachbetreuung und Content Creation, um das geschärfte Unternehmensprofil konstant nach außen zu tragen.',

    serviceIntro: 'Von der Strategie und Markenentwicklung über Werbung bis hin zur Maintenance bieten wir unseren Kunden maßgeschneiderte Lösungen, die sie wirklich brauchen. Schnell, einfach und mit dem gewissen Etwas.',
}

export default serviceData;